import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/GP1SU06V_High_res.jpg";
export const meta = {
  name: "brazil",
  title: "Corrida do ouro movimenta o mercado de mercúrio em Roraima",
  intro: "A maior e mais industrializada economia da América do Sul, o Brasil, não produz mercúrio. Mas as suas minas de ouro ilegais em plena expansão não podem funcionar sem a substância tóxica.",
  by: "Por Sam Cowie (texto). Videos por Rafael Norton, Luiz Felipe Saleh e Erik Von Poser.",
  image: Image,
  next: {
    slug: "guyana",
    title: "O Metal Tóxico à Sombra da Indústria do Ouro",
    description: "Apesar do seu risco à saúde e ao ambiente, o mercúrio é amplamente utilizado na extração de ouro, e para os mineiros na Amazônia, o líquido prateado é quase tão valioso como o próprio ouro."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Roraima, o Estado mais`}</strong>{` ao norte do Brasil não possui `}<a parentName="p" {...{
        "href": "https://www.bbc.com/portuguese/internacional-48534473"
      }}>{`nenhuma mina de ouro legalizada`}</a>{`. Mas a estátua de sete metros de altura do `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://g1.globo.com/rr/roraima/noticia/2020/06/08/monumento-ao-garimpeiro-e-alvo-de-pichacao-em-boa-vista.ghtml"
        }}>{`Monumento ao Garimpeiro`}</a></strong>{` no centro da sua capital, a tranquila Boa Vista, deixa poucas dúvidas de onde residem muito do entusiasmo local.`}</p>
    <p>{`A cada dia, frotas de carros, caminhões, `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/01/09/exercito-prende-15-garimpeiros-em-barcos-carregados-de-material-para-garimpo-na-terra-yanomami.ghtml"
      }}>{`barcos, pequenos aviões`}</a>{` e `}<a parentName="p" {...{
        "href": "https://folhabv.com.br/noticia/POLICIA/Ocorrencias/Helicoptero-que-era-usado-no-garimpo-e-apreendido-no-Paredao/65710"
      }}>{`até helicópteros`}</a>{` transportam homens com suprimentos para a mineração e ouro de e para centenas de áreas de mineração ilegal em reservas indígenas e rios nesse Estado amazônico pouco povoado. Alguns dos veículos carregam embalagens de mercúrio, a tóxica substância prateada amplamente utilizada pelos mineiros para o processamento do ouro.`}</p>
    <Video url="https://www.youtube.com/embed/7iIYvI604ac" mdxType="Video" />
    <p>{`Em uma madrugada recente, no final de junho, a polícia militar parou um carro com homens que se dirigiam a Boa Vista, voltando de um garimpo ilegal no distrito de Alto Alegre, onde está localizada parte da vasta reserva indígena Yanomami brasileira. De acordo com relatos, dias antes dois Yanomami  teriam sido mortos a tiros, e vários outros, feridos por garimpeiros na região.`}</p>
    <p>{`Ao revistar o carro, os policiais encontraram oito munições, um conjunto de balanças digitais e duas pequenas garrafas de Coca-Cola contendo 10 kg de mercúrio; uma apreensão relativamente grande da substância na beira da estrada, mas apenas uma fração do que é usado no Estado a cada ano.`}</p>
    <p>{`O Brasil, a maior e mais industrializada economia da América do Sul, não produz mercúrio. E, mesmo antes de 2017, `}<a parentName="p" {...{
        "href": "http://www.mercuryconvention.org/DNNAdmin/AllENGLISHNewsEntry/tabid/3444/articleType/ArticleView/articleId/158175/language/en-US/Brazil-brings-to-74-the-number-of-future-Parties-to-the-Minamata-Convention.aspx"
      }}>{`quando o país ratificou a Convenção de Minamata`}</a>{`, um tratado internacional para proteger a saúde humana das emissões de mercúrio, as vendas foram oficialmente proibidas e as agências federais mantiveram um `}<a parentName="p" {...{
        "href": "https://www.icmbio.gov.br/cepsul/images/stories/legislacao/Instrucao_normativa/2015/in_ibama_08_2015_estabelece_cadastro_tecnico_federal_atividades_poluidoras__rvg_p_ibama_395_1989_p_435_1989_p_32_1995_p_46_1996.pdf"
      }}>{`controle rigoroso`}</a>{` sobre as importações registradas.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Se quase todos os mineiros artesanais da Amazônia brasileira usam mercúrio, essa nova corrida do ouro certamente levou a um aumento do uso ilícito da substância."`}</p>
    </blockquote>
    <p>{`Mas nos últimos anos tem havido um aumento notável da mineração ilegal em Roraima, especialmente na `}<a parentName="p" {...{
        "href": "https://news.mongabay.com/2019/07/yanomami-amazon-reserve-invaded-by-20000-miners-bolsonaro-fails-to-act/"
      }}>{`reserva indígena Yanomami`}</a>{`. E, dado que quase todos os mineiros artesanais da Amazônia brasileira usam mercúrio, de acordo com estudos do governo, essa nova corrida do ouro certamente levou a um aumento do uso ilícito de mercúrio.`}</p>
    <Video url="https://www.youtube.com/embed/s6wbj5-VR8w" mdxType="Video" />
    <p>{`O mercúrio utilizado em milhares de garimpos em toda a Amazônia brasileira é normalmente contrabandeado dos países vizinhos ou importado legalmente para o uso na produção industrial do país, sendo depois canalizado para redes clandestinas.`}</p>
    <p>{`O relatório de 2020 do Ministério Público Federal do Brasil "Mineração ilegal de ouro na Amazônia: marcos jurídicos e questões controversas",  destaca a importância das "ações de comando e controle de fronteiras e dos mercados informais de comercialização do mercúrio", caso o Brasil queira ser bem-sucedido em suas metas com base na Convenção de Minamata.`}</p>
    <p>{`“Precisamos de fiscalizações permanentes e estruturais do lado brasileiro”, disse a deputada Joênia Wapichana, de Roraima, única parlamentar indígena do Brasil. “Mas também precisamos de medidas mais rígidas dos países produtores de mercúrio”, acrescentou.`}</p>
    <h2>{`Garimpo em Roraima`}</h2>
    <p>{`Acredita-se que hoje milhares de garimpeiros estão extraindo ouro das ricas reservas indígenas de Roraima, destruindo habitats naturais e colocando em risco as populações locais, risco esse que aumentou com a crise da COVID-19. As repetidas operações de combate à mineração ilegal realizadas nos últimos anos pela Polícia Federal brasileira, pelas agências de fiscalização ambiental e pelas Forças Armadas levaram a várias prisões, grandes apreensões de ouro e outros bens, mas basicamente falharam em deter seu crescimento.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“A maior parte da economia do Estado continua a funcionar pela mineração ilegal e por isso a pressão é enorme”, disse uma fonte.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGNqqIlNAT/xAAbEAACAwADAAAAAAAAAAAAAAABAgADERIhI//aAAgBAQABBQLjjGsNDUJV6QdOmMP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAAREQISIxUf/aAAgBAQAGPwLKmy7I4S0f/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFR8P/aAAgBAQABPyEqVqaxyoFpqwuGzkB8DB7xP//aAAwDAQACAAMAAAAQ5N//xAAYEQACAwAAAAAAAAAAAAAAAAAAARExUf/aAAgBAwEBPxBUSsP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8QrX//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFh0f/aAAgBAQABPxBtD9MZwEIb78jFRciRK3GGrq4jrg5GqV3KSbsG9DP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a18989589fb695699de7e5d3d936fcd1/e93cc/GP1SU06I_High_res.webp 300w", "/static/a18989589fb695699de7e5d3d936fcd1/b0544/GP1SU06I_High_res.webp 600w", "/static/a18989589fb695699de7e5d3d936fcd1/68fc1/GP1SU06I_High_res.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a18989589fb695699de7e5d3d936fcd1/73b64/GP1SU06I_High_res.jpg 300w", "/static/a18989589fb695699de7e5d3d936fcd1/3ad8d/GP1SU06I_High_res.jpg 600w", "/static/a18989589fb695699de7e5d3d936fcd1/6c738/GP1SU06I_High_res.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a18989589fb695699de7e5d3d936fcd1/6c738/GP1SU06I_High_res.jpg",
            "alt": "Acredita-se que milhares de garimpeiros estão extraindo ouro das ricas reservas indígenas de Roraima.",
            "title": "Acredita-se que milhares de garimpeiros estão extraindo ouro das ricas reservas indígenas de Roraima.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Acredita-se que milhares de garimpeiros estão extraindo ouro das ricas reservas indígenas de Roraima. `}<strong parentName="em">{`Photo by Greenpeace`}</strong>{` `}</em></p>
    <p>{`A maioria dos próprios garimpeiros são homens pobres - às vezes mulheres -, muitas vezes explorados na base de uma pirâmide lucrativa de contrabando de ouro, do comércio ilegal e de logísticas, que, anualmente, movimentam bilhões de dólares em todo o Brasil.`}</p>
    <p>{`É impossível obter as cifras exatas, devido à natureza ilícita do comércio. Estimam-se, no entanto, que milhares de quilos de ouro - incluindo, mais recentemente, quantias crescentes da vizinha Venezuela - são anualmente extraídos e traficados ilegalmente de Roraima, roubando do Estado muito da sua necessária receita tributária.`}</p>
    <p>{`O processo é possibilitado pela pobreza do Estado, por suas fronteiras porosas e pelas agências de fiscalização sobrecarregadas, mas também por autoridades locais corruptas, empresários e investidores inescrupulosos.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Há uma tolerância social enorme na sociedade de Roraima, o garimpeiro ilegal não é visto como um criminoso”
disse Alisson Marugal, procurador da República em Roraima.`}</p>
    </blockquote>
    <p>{`É um risco que compensa, ao menos para alguns. Os preços globais do ouro alcançaram níveis recordes, em torno de US$ 62.000 o quilo (preço em 4 de setembro), enquanto as agências de proteção ambiental e indígena do Brasil foram `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-health-coronavirus-brazil-environment/exclusive-brazil-scales-back-environmental-enforcement-amid-coronavirus-idUSKBN21E15H"
      }}>{`prejudicadas pela crise da COVID-19`}</a>{` e pela `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-brazil-environment/televised-raid-on-illegal-amazon-mining-angers-brazil-government-leads-to-sacking-idUSKCN21W31A"
      }}>{`interferência`}</a>{` do governo do presidente Jair Bolsonaro.`}</p>
    <p>{`O líder de extrema direita foi eleito presidente em 2018 e `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/eleicoes/2018/noticia/2018/10/29/jair-bolsonaro-vence-em-12-das-15-cidades-de-roraima.ghtml"
      }}>{`teve uma vitória retumbante`}</a>{` aqui em Roraima, após uma campanha repleta de retórica pró-garimpeiro, incluindo promessas de afrouxar as regulamentações ambientais e legalizar a mineração em reservas indígenas.`}</p>
    <Video url="https://www.youtube.com/embed/Vqef9JdC1D0" mdxType="Video" />
    <blockquote>
      <p parentName="blockquote">{`“Se eu fosse rei de Roraima, em 20 anos teríamos uma economia igual a do Japão”, disse Bolsonaro durante uma `}<a parentName="p" {...{
          "href": "https://folhabv.com.br/noticia/POLITICA/Roraima/Bolsonaro---Se-eu-fosse-rei-de-Roraima--estado-teria-economia-igual-a-do-Japao-/38719"
        }}>{`visita em 2018`}</a>{`.`}</p>
    </blockquote>
    <p>{`Durante décadas, hordas de garimpeiros de todo o Brasil se aglomeraram neste El Dorado amazônico, na esperança de encontrar sua fortuna. Durante a década de 1980, cerca de 40 mil mineiros invadiram as terras Yanomami, que são maiores do que Portugal.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Durante décadas, hordas de mineiros ilegais de todo o Brasil se aglomeraram neste El Dorado amazônico, na esperança de encontrar sua fortuna.`}</p>
    </blockquote>
    <p>{`Como resultado, cerca de 15 a 20 por cento da tribo foi extinta por causa das doenças e da violência, dizem os antropólogos. Os garimpeiros acabaram sendo expulsos em 1992, quando a reserva foi oficialmente demarcada pelo governo brasileiro, embora sempre voltassem.`}</p>
    <p>{`Em julho deste ano, a Polícia Federal prendeu Pedro Emiliano Garcia, de 59 anos, um infame ex-garimpeiro - o único brasileiro vivo a ser condenado por genocídio, após um massacre nas terras Yanomami em 1993 - com 2kg de ouro em Boa Vista, `}<a parentName="p" {...{
        "href": "http://www.pf.gov.br/imprensa/noticias/2020/07-noticias-de-julho-de-2020/pf-prende-suspeito-de-atuar-na-logistica-do-garimpo-em-terra-indigena-ianomami"
      }}>{`acusado de operar aviões`}</a>{`, transportar mineiros para a reserva e ser o “proprietário” de uma mina no local.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "746px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAVnYIe9kn//EABgQAQEBAQEAAAAAAAAAAAAAAAIBAwQy/9oACAEBAAEFAtbZLuyn0wKeerRh4ZjQf//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAwEBPwHEf//EABYRAQEBAAAAAAAAAAAAAAAAAAASQf/aAAgBAgEBPwHVP//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQISITH/2gAIAQEABj8C56UrlsypIK5sjV4cs//EABoQAQEBAAMBAAAAAAAAAAAAAAEAEUFRYXH/2gAIAQEAAT8h1L+L1eZimTqWt7AjHOJ2V1rf/9oADAMBAAIAAwAAABDj3//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGH/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAYf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQVFxkbH/2gAIAQEAAT8QIK6DTBBoXRUb4qYHRqxPsRal5V4lpKGlhzL2Zyovqf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/62786a741109ea817671bd630b79ca09/e93cc/foto-policia-federal.webp 300w", "/static/62786a741109ea817671bd630b79ca09/b0544/foto-policia-federal.webp 600w", "/static/62786a741109ea817671bd630b79ca09/16dd7/foto-policia-federal.webp 746w"],
            "sizes": "(max-width: 746px) 100vw, 746px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/62786a741109ea817671bd630b79ca09/73b64/foto-policia-federal.jpg 300w", "/static/62786a741109ea817671bd630b79ca09/3ad8d/foto-policia-federal.jpg 600w", "/static/62786a741109ea817671bd630b79ca09/2ca71/foto-policia-federal.jpg 746w"],
            "sizes": "(max-width: 746px) 100vw, 746px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/62786a741109ea817671bd630b79ca09/2ca71/foto-policia-federal.jpg",
            "alt": "Em julho deste ano, a Polícia Federal prendeu Pedro Emiliano Garcia, de 59 anos, o único brasileiro vivo a ser condenado por genocídio.",
            "title": "Em julho deste ano, a Polícia Federal prendeu Pedro Emiliano Garcia, de 59 anos, o único brasileiro vivo a ser condenado por genocídio.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Em julho deste ano, a Polícia Federal prendeu Pedro Emiliano Garcia, de 59 anos, o único brasileiro vivo a ser acusado de genocídio. `}<strong parentName="em">{`Foto por Polícia Federal`}</strong>{` `}</em></p>
    <p>{`Isolada do restante do país, a outra única capital brasileira acessível de Roraima por rodovia é Manaus, a capital metropolitana da selva do Estado do Amazonas, ela própria apenas acessível por avião, uma longa viagem de barco através do rio ou guiando pelos 870 km da BR 319, rodovia não pavimentada, que pode ser virtualmente impossível de cruzar durante a estação chuvosa de novembro a março.`}</p>
    <p>{`Mas Roraima compartilha fronteiras terrestres porosas com a Venezuela e a Guiana. A primeira foi abalada por sua própria corrida do ouro e por uma crise humanitária sem precedentes, com milhares de venezuelanos refugiados desesperados se estabelecendo no vizinho Estado brasileiro nos últimos anos.
Também houve um aumento no fluxo de contrabando, incluindo ouro, armas, cocaína e mercúrio, dizem os especialistas em segurança locais, com venezuelanos explorados, frequentemente coagidos a trabalhar para grupos criminosos.`}</p>
    <p>{`Enquanto isso, a Guiana, um dos países mais pobres da América do Sul, com uma população de 780.000 habitantes, que recentemente começou a perfurar enormes reservas de petróleo marítimas, continua importando grandes quantidades de mercúrio - ao menos 34,5 toneladas em 2019 -, para usar em suas próprias operações de mineração artesanal de ouro.`}</p>
    <p>{`Parte dele é traficado para Roraima, de acordo com várias fontes locais ouvidas pelo InfoAmazonia, que apontaram a Guiana como a principal fonte de mercúrio ilícito no Estado.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"O acesso desse mercúrio (da Guiana) aos brasileiros é muito fácil”, disse um especialista em mineração ilegal em Roraima.`}</p>
    </blockquote>
    <h2>{`Acesso fácil`}</h2>
    <p>{`A fronteira de Roraima com a Guiana é formada pelo Rio Tacutu. Em muitos pontos o rio tem menos de 100 m de largura. Possui travessias ilegais e, durante a estação da seca, pode até ser possível caminhar para o outro lado.`}</p>
    <p>{`O rio é uma rota consolidada entre os dois países de tráfico de pessoas e contrabando de itens como álcool, produtos alimentícios, suprimentos médicos e, segundo fontes, mercúrio.`}</p>
    <p>{`“Eles não precisam passar pela estrada. Eles vêm pelo rio e trazem a quantidade que querem. É muito fácil de comprar ”, disse uma fonte".
“A região, devido às suas características inóspitas, é uma grande facilitadora para qualquer transporte ilícito”, disse uma fonte policial.`}</p>
    <p>{`De acordo com uma fonte da Polícia Rodoviária Federal do Brasil, responsável por apreensões na beira das estradas, parte do mercúrio é contrabandeado pela fronteira com a Guiana por meio de táxis e levado para Boa Vista. Esta versão também foi corroborada por fontes ouvidas para esta reportagem em Georgetown, capital da Guiana. A densidade da substância também a torna um produto fácil de contrabandear.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Uma garrafa de 2 litros de Coca-Cola pode conter 20 kg de mercúrio”, disse uma fonte. “É altamente improvável que você encontre uma tonelada de mercúrio, isso é o que torna as inspeções difíceis”.`}</p>
    </blockquote>
    <p>{`Assim como a densidade, o tráfico de mercúrio é auxiliado pela ambiguidade da substância, de certa forma legal. Diferentemente da cocaína, da maconha ou do ouro, por exemplo, apesar das suas qualidades destrutivas, muitas pessoas não sabem que é ilegal, incluindo muitos policiais, disseram alguns.`}</p>
    <p>{`“A maioria das pessoas não vê isso como um crime”, disse um agente da polícia federal, que antigamente trabalhava na Guiana.`}</p>
    <p>{`Várias outras fontes sugeriram que pequenas quantidades de mercúrio foram trazidas da Venezuela, especialmente através do rio Uraricoera, mas pequenas quantidades de cada vez.`}</p>
    <p><strong parentName="p">{`Território Yanomami e os garimpos mapeados`}</strong></p>
    <iframe src="https://infoamazonia.org/en/embed/?map_only=1&map_id=59813&width=960&height=480" width="960" height="480" frameBorder="0"></iframe>
    <p>{`“É o chamado trabalho de formiga, cada um leva 0,5l ou 1l”, disse uma fonte da polícia.
Outra fonte, com conexões com a mineração ilegal, disse que os aviões e os helicópteros das minas em Roraima, que pousavam no lado venezuelano da fronteira, às vezes pegavam mercúrio e o distribuíam no território Yanomami.`}</p>
    <p>{`Existem até algumas evidências que sugerem que parte do mercúrio contrabandeado a Roraima também está sendo levado para outras regiões do Brasil.
No ano passado, a polícia rodoviária de Roraima, Estado vizinho do Amazonas, prendeu um homem com 150 kg de mercúrio em quatro garrafas de metal, aparentemente avaliado em R$ 90 mil, junto com 35 mil carteiras de cigarros.`}</p>
    <p>{`Ele disse ter saído de Boa Vista e transportado o mercúrio para Manaus, que depois seria enviado para Itaituba, o mais importante `}<strong parentName="p">{`pólo`}</strong>{` de mineração ilegal da Amazônia brasileira.`}</p>
    <Video url="https://www.youtube.com/embed/6mVE6V9aaWs" mdxType="Video" />
    <p>{`Em outras partes do Brasil, acredita-se que uma grande quantidade de mercúrio entra pela Bolívia, o segundo maior importador mundial depois da Índia, importando 196 toneladas em 2018, de acordo com estimativas do Banco Mundial.`}</p>
    <p>{`Os Estados de Rondônia e Mato Grosso, que compartilham enormes fronteiras terrestres com a Bolívia, têm suas próprias extensas indústrias de mineração ilegal, bem como acesso rodoviário a grande parte da Amazônia brasileira.`}</p>
    <p>{`No início deste ano, dois homens foram presos na rodovia BR-364, em Rondônia, transportando `}<a parentName="p" {...{
        "href": "https://g1.globo.com/ro/rondonia/noticia/2020/01/25/80-quilos-de-mercurio-sao-apreendidos-com-dupla-em-carro-na-br-364.ghtml"
      }}>{`80kg de mercúrio`}</a>{`, que, segundo eles, seriam vendidos em minas ilegais na cidade de Ariquemes.`}</p>
    <p>{`O número de rotas potenciais mostra que o mercúrio é uma substância ágil, que pode se adaptar às diversas condições, incluindo restrições, apreensões e aumento de preço. Como muitas substâncias ilegais, acaba chegando onde existe demanda.`}</p>
    <p>{`“É a mesma lógica do tráfico de drogas”, disse um perito forense da Polícia Federal baseado na região do Rio Tapajós, que preferiu permanecer anônimo. “Quantidades maiores são levadas aos locais e então divididas em quantidades menores para serem vendidas”.`}</p>
    <p>{`Em toda a Amazônia brasileira, mercadorias vendidas em lugares distantes de mineração ilegal - muitas vezes acessíveis apenas por avião ou barco - como água, Coca-Cola, comida enlatada, álcool e serviço de internet, são vendidas a preços muito inflacionados e com o mercúrio acontece a mesma coisa.`}</p>
    <Sidenote side="right" mdxType="Sidenote">
	Estudo do Inventário Nacional de Emissões de Mercúrio de 2018 avaliava em R$ 600 - 1.200 o quilo de mercúrio, dependendo do Estado.
    </Sidenote>
    <p>{`Os preços do mercúrio no Brasil parecem flutuar, dependendo da região. Uma fonte disse que o mercúrio poderia ser comprado localmente em Roraima por R$ 500 o quilo, enquanto o Estudo do Inventário Nacional de Emissões de Mercúrio de 2018 avaliava em R$ 600 - 1.200 o quilo de mercúrio, dependendo do Estado.`}</p>
    <p>{`O mercúrio também está aparentemente bastante disponível `}<a parentName="p" {...{
        "href": "https://www.construvolts.com.br/mercrio-vivo-azougue-100g-p136"
      }}>{`online`}</a>{`, mas por valores muito mais altos, R$ 850 por 100g, por exemplo.`}</p>
    <p>{`Enquanto isso, no relatório de 2020 `}<a parentName="p" {...{
        "href": "http://www.escolhas.org/wp-content/uploads/2020/05/TD_04_GARIMPO_A-NOVA-CORRIDA-DO-OURO-NA-AMAZONIA_maio_2020.pdf"
      }}>{`A nova corrida do ouro na Amazônia`}</a>{`, da ONG ambientalista brasileira Instituto Escolhas, uma fonte pró-garimpo da região do Rio Tapajós alegou que o mercúrio custava R$ 1.500 e, portanto, era usado com "moderação".`}</p>
    <h2>{`Emissões de mercúrio`}</h2>
    <p>{`As estimativas sobre a quantidade de mercúrio lançado no meio ambiente brasileiro a cada ano variam bastante.`}</p>
    <p>{`Os grupos garimpeiros insistem que as emissões diminuíram nos últimos anos, devido ao aumento do `}<a parentName="p" {...{
        "href": "https://lista.mercadolivre.com.br/recuperador-de-merc%C3%BArio-cadinho-para-garimpo"
      }}>{`uso de retortas`}</a>{` - um objeto de metal que parece uma grande chaleira, facilmente disponível para compra e relativamente barata online - que pode recuperar grandes quantidades de mercúrio.`}</p>
    <p>{`Os varejistas de retorta afirmam que podem ser recuperados 100 por cento, enquanto estudos sugerem algo em torno de 70 por cento, em oposição aos métodos de filtragem padrão, que recuperam em média 50 por cento.`}</p>
    <p>{`O estudo mais abrangente do Brasil sobre o uso de mercúrio na mineração artesanal, o Inventário Nacional de Emissões de Mercúrio, publicado em 2018, concluiu que algo entre 18,5 e 221,5 toneladas de mercúrio foram lançadas no meio ambiente em 2016, cerca de dois terços das quais vão para a atmosfera e o restante para o  solo e os rios.`}</p>
    <p>{`A grande variação depende da quantidade de ouro extraído ilegalmente, que, de acordo com o relatório, pode variar entre 50 a 100 por cento da quantia declarada, embora, devido às dificuldades de medição, também possa ser maior.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Nos primeiros quatro meses de 2020, o Brasil exportou 29 toneladas de ouro, um aumento de 15 por cento em relação ao mesmo período de 2019.`}</p>
    </blockquote>
    <p>{`Nos primeiros quatro meses de 2020, o Brasil exportou 29 toneladas de ouro, um aumento de 15 por cento em relação ao mesmo período de 2019, segundo o relatório do Instituto Escolhas.`}</p>
    <p>{`De acordo com o Inventário Nacional de Emissões de Mercúrio, a quantidade de mercúrio liberada no meio ambiente também depende de quanto o uso de retortas e das técnicas de filtragem é comum.`}</p>
    <p>{`As técnicas de filtragem, que podem recuperar até 50 por cento do mercúrio, eram praticamente universais entre os 26 grupos de mineração artesanal estudados no relatório, o que faz sentido, dado o custo da substância, observou o relatório.`}</p>
    <p>{`O relatório concluiu que, em média, os grupos de mineração artesanal no Brasil usariam 1g de mercúrio para produzir 1g de ouro, embora a média global seja de 3:1 de mercúrio para ouro.`}</p>
    <p>{`“Com um quilo de mercúrio você pode trabalhar por meses”, disse uma fonte. “Mas com tanta gente trabalhando, aos poucos os danos se acumulam.”`}</p>
    <h2>{`Impactos na saúde`}</h2>
    <p>{`Em uma decisão histórica recente, motivada pela crise da COVID-19 e pelo pedido de grupos de defesa dos indígenas,  a Suprema Corte do Brasil ordenou que as autoridades deveriam agir para retirar os milhares de garimpeiros da reserva Yanomami, onde ao menos quatro indígenas morreram da doença.`}</p>
    <p>{`Mas mesmo que todos os mineiros partam, um resultado altamente ambicioso, reduzindo os riscos de transmissão da COVID-19, outro assassino silencioso permanece: o mercúrio.`}</p>
    <p>{`O uso da substância na mineração artesanal pode envenenar rios locais e cadeias alimentares. Em 2019, em audiência pública na Câmara dos Deputados brasileira, pesquisadores apresentaram um estudo que relacionava a paralisia cerebral em crianças indígenas em áreas de mineração à exposição pré-natal ao mercúrio.`}</p>
    <Video url="https://youtube.com/embed/gFpOCFpCn8A" mdxType="Video" />
    <p>{`No mesmo ano, uma pesquisa da Fundação Oswaldo Cruz do Brasil descobriu que `}<a parentName="p" {...{
        "href": "https://oglobo.globo.com/brasil/estudo-da-fiocruz-mostra-que-56-dos-ianomamis-tem-mercurio-acima-do-limite-23852233"
      }}>{`56 por cento`}</a>{` das mulheres e crianças indígenas testadas da reserva indígena Yanomami tinham níveis de mercúrio acima dos limites da Organização Mundial de Saúde.`}</p>
    <p>{`Paulo Cesar Basta, um professor de saúde pública que conduziu a pesquisa, destacou os perigos do mercúrio: sua longevidade e capacidade de permanecer durante muito tempo no meio ambiente, sua capacidade de transitar entre águas, solos e peixes e sua capacidade de se acumular cada vez mais em humanos e em outros animais.`}</p>
    <p>{`“Isso coloca em risco toda uma geração de crianças”, disse ele.`}</p>
    <p>{`Hoje, as terras indígenas de Roraima enfrentam uma pressão crescente da mineração ilegal e do uso do mercúrio, em parte devido às promessas do presidente Jair Bolsonaro de legalizar a atividade do garimpo, dizem os defensores locais.`}</p>
    <p>{`As pressões também vêm de dentro das comunidades. O senador pró-mineração de Roraima e aliado de Bolsonaro, Chico Rodrigues, gravou recentemente um vídeo sobre a comunidade indígena de Napoleão, incentivando a atividade, ao lado de lideranças indígenas pró-garimpo, incluindo Irisnaide Silva, que `}<a parentName="p" {...{
        "href": "https://abracambrasil.org.br/noticias/217-bolsonaro-recebe-indigenas-no-palacio-do-planalto-e-defende-mineracao-e-agropecuaria-em-suas-terras"
      }}>{`se encontrou com Bolsonaro`}</a>{` em Brasília no ano passado.`}</p>
    <p>{`Enquanto isso, na reserva indígena Raposa Serra do Sol, a segunda maior reserva do Estado, a recente invasão por cerca de 2.000 garimpeiros ilegais e o uso generalizado de mercúrio podem envenenar o abastecimento de água e as cadeias alimentares.`}</p>
    <p>{`“Temos medo de que, com o início da temporada das chuvas, esse material (mercúrio) vá acabar atingindo rios e córregos, que antes não estavam contaminados”, disse Ivo Cípio Aureliano, advogado e assessor jurídico do Conselho Indígena de Roraima.`}</p>
    <h2>{`Apreensão histórica`}</h2>
    <Sidenote side="right" mdxType="Sidenote">
	Entre 2006 e 2016, o Brasil importou em média 25 toneladas anuais; 20 toneladas em 2018, segundo o Banco Mundial.
    </Sidenote>
    <p>{`O Brasil continua a importar mercúrio para sua própria produção industrial, principalmente em São Paulo e no rico Estado do sul do país, Santa Catarina. Entre 2006 e 2016, o Brasil importou em média 25 toneladas anuais; 20 toneladas em 2018, segundo o Banco Mundial.`}</p>
    <p>{`Em 2018 a agência ambiental do Brasil, o Ibama, apreendeu um recorde de 430 kg de mercúrio e interceptou outros 1.700 kg, que estavam sendo importados legalmente da Turquia pela empresa química de Santa Catarina Quimidrol, que o Ibama alegou que seria desviado para o uso nas minas ilegais da Amazônia.
Durante anos, a empresa importou mercúrio que era usado no mercado brasileiro de ortodontologia, cujo uso se tornou obsoleto, quando novas tecnologias odontológicas surgiram, disseram os pesquisadores.`}</p>
    <p>{`O mercúrio apreendido, porém, segundo o Ibama, deveria ser entregue a uma empresa de fachada no Estado de Mato Grosso, na fronteira com a Amazônia. Ela recebeu 8.700 kg de mercúrio da empresa química entre 2014 e 2017.`}</p>
    <p>{`Documentos judiciais vistos pelo InfoAmazonia sobre o recurso da empresa, que foi rejeitado, mencionavam citações de um relatório confidencial do Ibama, que destacava que a empresa de fachada estava ligada a um conhecido fornecedor de mercúrio ilícito na região amazônica do sul do Brasil.`}</p>
    <p>{`“Com a apreensão de uma quantia tão grande seria razoável imaginar que houvesse uma brecha no mercado e que as rotas de contrabando teriam aumentado”, disse um investigador que trabalhou no caso.`}</p>
    <h2>{`Imagem maior`}</h2>
    <p>{`Fontes ouvidas pelo InfoAmazonia em Roraima disseram que a grande maioria das pessoas que contrabandeava mercúrio para o Estado era amadora, mesmo que em última análise trabalhassem em nome de grandes investidores ilegais de mineração.`}</p>
    <p>{`Máquinas como plataformas de dragagem , que enchem os rios do território Yanomami, exigem custos iniciais mínimos de R$ 150 mil e os proprietários muitas vezes também possuem um negócio legítimo, como um posto de gasolina, um hotel, um supermercado ou uma loja de roupas.`}</p>
    <p>{`“Nunca será identificado quem é o dono”, disse uma fonte. “Ele vai colocar um garimpeiro experiente como seu gerente”, acrescentou. “E esse cara vai mandar alguém adquirir o mercúrio.”`}</p>
    <p>{`Plataformas de dragagem e outras máquinas utilizadas na mineração ilegal, como os moinhos (trituradores de rocha) na reserva Raposa Serra do Sol, requerem grande quantidade de gasolina para funcionar constantemente e é comum em Roraima que caminhões transportando milhares de litros de gasolina sejam parados e suas  cargas, apreendidas.`}</p>
    <p>{`O mercúrio e os homens muitas vezes chegam às minas remotas por meio de pequenas aeronaves e companhias aéreas que operam em Roraima, que receberam contratos públicos milionários e também têm sido `}<a parentName="p" {...{
        "href": "https://oglobo.globo.com/brasil/anac-abre-processo-para-apurar-possiveis-irregularidades-de-empresa-de-taxi-aereo-suspeita-de-atuar-em-garimpo-ilegal-1-24530676"
      }}>{`investigadas`}</a>{` por operar na logística aérea da mineração ilegal.`}</p>
    <p>{`“Sem logística você não tem garimpo”, disse o Sr. Marugal, o Promotor Federal.`}</p>
    <p>{`O transporte aéreo em Roraima é um mercado tão lucrativo que se acredita que até tenha levado ao `}<a parentName="p" {...{
        "href": "http://g1.globo.com/brasil/noticia/2011/05/dono-de-empresa-aerea-e-morto-tiros-em-frente-pizzaria-de-boa-vista.html"
      }}>{`assassinato`}</a>{` de `}<a parentName="p" {...{
        "href": "https://mp-rr.jusbrasil.com.br/noticias/100044596/acusado-de-executar-empresario-da-aviacao-e-condenado-a-21-anos"
      }}>{`empresários rivais`}</a>{` no passado.`}</p>
    <p>{`Os helicópteros também estão sendo usados cada vez mais, de acordo com uma fonte, e houve várias apreensões reportadas recentemente, após esforços das agências de fiscalização para destruir pistas de pouso clandestinas.`}</p>
    <h2>{`Ouro venezuelano`}</h2>
    <p>{`Para complicar ainda mais as coisas para, o ouro das centenas de minas ilegais da Venezuela está sendo cada vez mais traficado para Roraima e para fora do Brasil na cadeia de abastecimento global, mostraram investigações da Polícia Federal.`}</p>
    <p>{`No ano passado, de acordo com o Comex Stat do Brasil, ao menos 668 quilos de ouro, que os agentes federais acreditam terem sido retirados da Venezuela e da reserva Yanomami, foram oficialmente exportados de Roraima, apesar de o Estado não ter uma operação legalizada.`}</p>
    <p>{`O ouro foi a segunda maior exportação do Estado em 2019, com a Índia como o principal comprador. Naquele ano, a maior importação do Estado foram de aviões e peças associadas, essenciais para a logística do comércio ilegal de mineração de ouro.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Para complicar ainda mais as coisas para, o ouro das centenas de minas ilegais da Venezuela está sendo cada vez mais traficado para Roraima e para fora do Brasil`}</p>
    </blockquote>
    <p>{`No início deste mês, uma brasileira foi presa na cidade fronteiriça de Pacaraima com 11 kg de ouro e US$ 4.200 em dinheiro. No mesmo dia, a Polícia Federal  `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/08/06/pf-deflagra-operacao-contra-comercio-e-contrabando-de-ouro-da-terra-yanomami-e-venezuela.ghtml"
      }}>{`fez uma busca e apreensão em vários endereços`}</a>{` em Roraima, assim como em São Paulo, visando a uma organização criminosa responsável pelo manuseio do ouro retirado ilegalmente da Venezuela e do território Yanomami.`}</p>
    <p>{`Um dos endereços é o mesmo no cerne do escândalo que estourou no `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2019/12/06/pf-mira-grupo-que-contrabandeou-12-tonelada-de-ouro-da-venezuela-por-rr-e-movimentou-r-230-milhoes.ghtml"
      }}>{`final do ano passado`}</a>{`, envolvendo redes criminosas brasileiras e venezuelanas, com um venezuelano procurado pela Interpol.`}</p>
    <p>{`O grupo, segundo a Polícia Federal, movimentou ao menos 1.200 kg de ouro em um período de dois anos. O ouro era contrabandeado da Venezuela para Roraima em pequenas quantias por meio de mulas e depois levado a São Paulo, onde era exportado para a Índia e para os Emirados Árabes Unidos.`}</p>
    <p>{`“Somos um Estado de fronteira, com todas as fragilidades resultantes disso”, disse o Sr. Marugal, o Promotor Federal`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      